<template>
  <div>
    <div v-if="getLoaded()" class="data-control">
      <Search />
      
      <div class="btn-set">
        <router-link to='user/add' class="btn btn-add">
          <i class="fa fa-plus"></i> Add <span class="mobile-hide">User</span>
        </router-link>
      </div>
    </div>

    <div v-if="users.row==0">
      <EmptyBlockComp />
    </div>
    <section v-else class="data-list">
      <table class="tb-data">
        <colgroup>
          <col class="col-name" />
          <col class="col-user" />
          <col class="col-email" />
          <col class="col-role" />
          <col class="col-date" />
        </colgroup>
        <thead>
          <tr>
            <th>Fullname</th>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Modified</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, i) in users.result" :key="i">
            <td>
              <div class="item-detail">
                <router-link :to="'user/edit/' + item.UID" class="item-title">{{ item.name }}</router-link>
              </div>
              <ul class="item-action">
                <li><router-link :to="'user/edit/' + item.UID" class="edit">Edit</router-link></li>
                <li><a href="#" @click.prevent="deleteItem(item)" class="delete" >Remove</a></li>
              </ul>
            </td>
            <td>{{ item.username }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.role | userRoleTitle }}</td>
            <td>{{ item.date_modified | formatDate }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <th>Fullname</th>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Modified</th>
          </tr>
        </tfoot>
      </table>

    </section>

    <div class="data-control control-foot">
      <!-- <Pagination slug='user' :limit=queryParams.limit :total=users.total /> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';
import {const_data_limit} from '@/variables/const.js';
import {cloneObject} from '@/utils/objects.js'

import EmptyBlockComp from '@/components/common/EmptyBlockComp.vue'
import Search         from '@/components/common/Search.vue'
// import Pagination  from '@/components/common/Pagination.vue'

export default {
  name: 'User',
  page_title: 'Users',
  data() {
    return {
      loaded: false,
      users: [],
      queryParams:{
        limit: const_data_limit, 
        page: 1
      }
    }
  },
  components: {
    EmptyBlockComp,
    Search,
    // Pagination
  },
  watch:{
    $route() {
      this.loadPage();
    }
  },
  created() {
    this.$store.commit('setPageTitle', 'Users');
    this.$store.commit('setHeadTitle', 'Users');
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    loadPage() {
      this.$store.commit('setLoaderShow', true);

      if(this.$route.query.page){
        this.queryParams.page = this.$route.query.page;
      }
      if(this.$route.query.q){
        this.queryParams.q = this.$route.query.q;
      }
      let queryParams = cloneObject(this.queryParams);
      // console.log(queryParams);

      apiRequest
        .get('/user', { params: queryParams })
        .then( (res) => {
          // console.log(res.data);
          this.users = res.data;
          this.$store.commit('setLoaderShow', false);
        })
    },
    deleteItem(data) {
      let conf = confirm(`Do you want to delete '${data.name}' ?`);

      if(conf) {
        this.$store.commit('setLoaderShow', true);
        
        apiRequest
          .post(
            '/user/delete',
            {
              auth: this.$store.state.user_session,
              id:   data.UID,
            }
          )
          .then( () => {
            this.loadPage();
          });
      }
    }
  }
}
</script>


<style scoped>
.tb-data .col-name  {width: 25%;}
.tb-data .col-user  {width: auto;}
.tb-data .col-email {width: 25%;}
.tb-data .col-role  {width: 15%;}
.tb-data .col-date  {width: 15%;}
</style>