
export const const_data_limit = 30;
export const category = {
  '1': { name:'ออกแบบ' },
  '2': { name:'ก่อสร้าง' },
  '3': { name:'ปรับปรุง' },
  '4': { name:'ซ่อมแซม' },
  '5': { name:'ตกแต่งภายใน'},
  '6': { name:'งานระบบ' },
  '7': { name:'อื่นๆ' },
};

export const property = {
  'job-cat': {
    name: 'ประเภทงาน',
  },
  'building_type': {
    name: 'ประเภทสิ่งก่อสร้าง'
  },
  'project_timeframe': {
    name: 'กำหนดการโครงการ'
  },
  'project_step': {
    name: 'ข้อมูลเพื่อใช้ในการดำเนินงาน'
  },
}

export const memberRatingCat = {
  '1': {name: 'คุณภาพงานก่อสร้าง'},
  '2': {name: 'ระยะเวลางานก่อสร้าง'},
  '3': {name: 'ความคุ้มค่า'},
  '4': {name: 'ความรู้/ความสามารถของผู้รับเหมา'},
  '5': {name: 'การบริหารจัดการของผ้รูับเหมา'},
  '6': {name: 'การประสานงาน/สื่อสารของผู้รับเหมา'},
  '7': {name: 'มารยาทของผู้รับเหมา'},
}