<template>
  <div class="data-search">
  <form autocomplete="off">
    <fieldset>
      <input type="text" name="q" v-model="keyword" :placeholder="getPlaceholder()"  autocomplete="off">
      <button type="submit">
        <i class="fa fa-search fa-lg"></i>
      </button>
    </fieldset>
  </form>
</div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Search by keyword ...'
    }
  },
  data() {
    return {
      keyword: '',
    }
  },
  created() {
    this.keyword = this.$route.query.q;
  },
  methods: {
    getPlaceholder() {
      return this.placeholder;
    }
  }
}
</script>